import React, { Fragment, useState, useEffect, useRef } from "react"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  mainContainer,
  hamburgerContainer,
  lineTop,
  lineTop__open,
  lineMiddle,
  lineMiddle__open,
  lineBottom,
  lineBottom__open,
  menu,
  menu__open,
  menuItem,
  menuItemAnchor,
  menuItemDropdown,
  subMenu,
  subMenu__open,
} from "../../sass/hamburger.module.scss"

library.add(faCaretDown, faCaretUp)

const MenuLinks = ({ links, isOpen }) => {
  const [isSubOpen, setIsSubOpen] = useState(false)

  return (
    <ul className={isOpen ? menu__open : menu}>
      {links.map(({ name, to, sublinks }, index) =>
        sublinks ? (
          <Fragment key={index}>
            <li className={menuItem} key={index}>
              <div
                className={menuItemDropdown}
                onClick={() => setIsSubOpen(!isSubOpen)}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
              >
                <FontAwesomeIcon
                  size="lg"
                  icon={`caret-${isSubOpen ? "down" : "up"}`}
                />
                <span>{name}</span>
              </div>
            </li>
            {sublinks.map(sublink => (
              <li
                className={isSubOpen ? subMenu__open : subMenu}
                key={sublink.name}
              >
                <a
                  className={menuItemAnchor}
                  href={sublink.to}
                  key={sublink.name}
                >
                  {sublink.name}
                </a>
              </li>
            ))}
          </Fragment>
        ) : (
          <li className={menuItem} key={index}>
            <a className={menuItemAnchor} href={to} key={index}>
              {name}
            </a>
          </li>
        )
      )}
    </ul>
  )
}

const Hamburger = ({ links }) => {
  const [isOpen, setIsOpen] = useState(false)
  const clickAreaRef = useRef()

  const onClickAreaClick = e => {
    e.stopPropagation()
    setIsOpen(!isOpen)
  }

  const handleClick = e => {
    const isClickOnClickArea = clickAreaRef.current.contains(e.target)
    if (isClickOnClickArea && isOpen === true) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClick, false)
    return () => document.removeEventListener("click", handleClick, false)
  })

  return (
    <div className={mainContainer}>
      <div
        ref={clickAreaRef}
        className={hamburgerContainer}
        onClick={onClickAreaClick}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
      >
        <div className={isOpen ? lineTop__open : lineTop} />
        <div className={isOpen ? lineMiddle__open : lineMiddle} />
        <div className={isOpen ? lineBottom__open : lineBottom} />
      </div>

      <MenuLinks links={links} isOpen={isOpen} />
    </div>
  )
}

export default Hamburger
