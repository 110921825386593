import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import {
  mainFooter,
  linksContainer,
  policyLinksContainer,
  logosLinksContainer,
  policyLink,
  logoLink,
  tileIcon,
  copyrightContainer,
} from "../sass/footer.module.scss"

const anchorsProps = {
  className: logoLink,
  target: "_blank",
  rel: "noopener noreferrer",
}

const Footer = () => {
  const data = useStaticQuery(graphql`
    fragment myIconData on ContentfulAsset {
      file {
        url
      }
      fluid {
        tracedSVG
      }
    }

    query FooterQuery {
      githubIcon: contentfulAsset(title: { eq: "github-tile-logo" }) {
        ...myIconData
      }
      githubIconHover: contentfulAsset(title: { eq: "github-hover" }) {
        ...myIconData
      }
      stackOverflowIcon: contentfulAsset(title: { eq: "stackoverflow-tile" }) {
        ...myIconData
      }
      stackOverflowIconHover: contentfulAsset(
        title: { eq: "stackoverflow-hover" }
      ) {
        ...myIconData
      }
      linkedinIcon: contentfulAsset(title: { eq: "linkedin-tile" }) {
        ...myIconData
      }
      linkedinIconHover: contentfulAsset(title: { eq: "linkedin-hover" }) {
        ...myIconData
      }
    }
  `)

  const [githubLogo, setGithubLogo] = useState(data.githubIcon.file.url)
  const [stackOverflowLogo, setStackOverflowLogo] = useState(
    data.stackOverflowIcon.file.url
  )
  const [linkedInLogo, setLinkedInLogo] = useState(data.linkedinIcon.file.url)

  const logosData = [
    {
      name: "Github",
      link: "https://github.com/GBouffard",
      src: githubLogo,
      iconHover: data.githubIconHover.file.url,
      icon: data.githubIcon.file.url,
      setFunction: setGithubLogo,
    },
    {
      name: "Stack Overflow",
      link: "https://stackoverflow.com/users/5019630/gbouffard",
      src: stackOverflowLogo,
      iconHover: data.stackOverflowIconHover.file.url,
      icon: data.stackOverflowIcon.file.url,
      setFunction: setStackOverflowLogo,
    },
    {
      name: "LinkedIn",
      link: "https://www.linkedin.com/in/gbouffard",
      src: linkedInLogo,
      iconHover: data.linkedinIconHover.file.url,
      icon: data.linkedinIcon.file.url,
      setFunction: setLinkedInLogo,
    },
  ]

  return (
    <footer className={mainFooter}>
      <div className={linksContainer}>
        <div className={policyLinksContainer}>
          <Link className={policyLink} to="/terms-and-conditions">
            Terms & Conditions
          </Link>
          <Link className={policyLink} to="/terms-and-conditions#privacypolicy">
            Privacy Policy
          </Link>
          <Link className={policyLink} to="/terms-and-conditions#cookiespolicy">
            Cookie Policy
          </Link>
        </div>

        <div className={logosLinksContainer}>
          {logosData.map(
            ({ name, link, src, iconHover, icon, setFunction }) => (
              <a
                key={name}
                href={link}
                onMouseEnter={() => setFunction(iconHover)}
                onMouseLeave={() => setFunction(icon)}
                {...anchorsProps}
              >
                <img
                  alt={`${name.toLowerCase()} icon`}
                  className={tileIcon}
                  src={src}
                  title={`See Guillaume's ${name}`}
                />
              </a>
            )
          )}
        </div>
      </div>

      <div
        className={copyrightContainer}
      >{`Copyright ${new Date().getFullYear()} © Guillaume Bouffard All rights Reserved.`}</div>
    </footer>
  )
}

export default Footer
