import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"

import {
  main,
  mainPP,
  header__logoImageContainer,
  header__logoImage,
} from "../sass/header.module.scss"
import TopNav from "./molecules/topNav"
import Hamburger from "./molecules/hamburger"

library.add(faCaretDown)

const links = [
  {
    name: "About",
    to: "/about",
  },
  {
    name: "Skills",
    to: "/skills",
  },
  {
    name: "Projects",
    sublinks: [
      {
        name: "Professional projects",
        to: "/professional-projects",
      },
      {
        name: "Personal projects",
        to: "/personal-projects",
      },
      {
        name: "Learning & development",
        to: "/learning-and-development",
      },
      // {
      //   name: "Open source",
      //   to: "/open-source",
      // },
    ],
  },
  {
    name: "CV",
    to: "/cv",
  },
  {
    name: "Contact",
    to: "/contact",
  },
  // {
  //   name: "Settings",
  //   to: "/settings",
  // },
]

const Header = ({ logo }) => {
  // I had to make a hack because zIndex update for modalProps does not work in gatsby-config.
  const isPersonalProjectModal =
    typeof window !== "undefined" &&
    window.location.pathname.includes("personal-project/")

  return (
    <header className={isPersonalProjectModal ? mainPP : main}>
      <Link className={header__logoImageContainer} to="/">
        <Img
          className={header__logoImage}
          alt="Guillaume Bouffard avatar"
          fluid={logo}
        />
      </Link>
      <TopNav links={links} />
      <Hamburger links={links} />
    </header>
  )
}

export default Header
