import React from "react"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  topNav__linksContainer,
  topNav__anchorLink,
  topNav__DropdownName,
  topNav__DropdownMenuDisplay,
  topNav__DropdownMenuItem,
  DropdownMenuItem__leftLine,
  DropdownMenuItem__rightLine,
  topNav__DropdownCaret,
} from "../../sass/topNav.module.scss"

library.add(faCaretDown)

const TopNav = ({ links }) => (
  <div className={topNav__linksContainer}>
    {links.map((link, index) =>
      link.sublinks ? (
        <div className={topNav__DropdownMenuDisplay} key={index}>
          <div className={topNav__DropdownName}>
            {link.name}
            <FontAwesomeIcon
              className={topNav__DropdownCaret}
              icon="caret-down"
            />
          </div>
          <ul>
            {link.sublinks.map(sublink => (
              <li key={sublink.name} className={topNav__DropdownMenuItem}>
                <div className={DropdownMenuItem__leftLine}></div>
                <div className={DropdownMenuItem__rightLine}></div>
                <a href={sublink.to} key={sublink.name}>
                  {sublink.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <a href={link.to} key={index} className={topNav__anchorLink}>
          {link.name}
        </a>
      )
    )}
  </div>
)

export default TopNav
